import { Suspense, lazy } from 'react'

import { useAuth } from 'context/auth-context'
import { FullPageLoader } from 'components/loaders/full-page-loader'

const AuthenticatedApp = lazy(() => import('./authenticated-app'))
const UnauthenticatedApp = lazy(() => import('./unauthenticated-app'))

export function App() {
  const { auth } = useAuth()

  if (auth === undefined) {
    return <FullPageLoader />
  }

  return (
    <Suspense fallback={<FullPageLoader />}>
      {auth?.user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  )
}
